import MailTemplateForm from '@/components/MailTemplateForm/MailTemplateForm.vue'

export default {
  components: {
    MailTemplateForm
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: 'Settings',
          to: { name: 'settings' },
          exact: true
        },
        {
          text: 'Mail Templates',
          disabled: false,
          to: { name: 'list-mail-templates' },
          exact: true
        },
        { text: 'Create' }
      ]
    }
  }
}