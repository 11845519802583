import allCountriesByCode from '@/store/allCountriesByCode.js'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('graphql', ['assetTypes'])
  },
  methods: {
    formatCountry(iso2) {
      return _.get(allCountriesByCode[iso2], 'name', iso2)
    },
    formatDateTimeByName(f) {
      switch (f.name) {
        case 'initialDeploymentDate':
        case 'contractStartDate':
        case 'contractEndDate':
          return this.formatDisplayDate(f.value)
        default:
          return this.formatDisplayDateTime(f.value)
      }
    },
    formatDisplayDate(v) {
      return v ? this.$moment(v).format('YYYY-MM-DD') : null
    },
    formatDisplayDateTime(v) {
      return v ? this.$moment(v).format('YYYY-MM-DD HH:mm:ss') : null
    },
    getRouteName(graphTypeName) {
      return this.assetTypes.find(at => at.graphTypeName === graphTypeName).name
    },
    formatTokenSubject(ts) {
      if (!ts) {
        return ''
      }
      return ts.replace('@rakuten.com', '')
    }
  }
}